import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { FormControl, Input, setRef } from '@mui/material';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import TermsAndConditionsModal from './TCModal';
import { Typography } from '@mui/material';
import { FUNDING, PayPalButtons, PayPalButtonsComponentProps, PayPalScriptProvider, ReactPayPalScriptOptions } from '@paypal/react-paypal-js';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

//===============================================================================================

import csklsclogo from './csklsc_logo.png'
import cskoba_fps_qrcode from './cskoba_fps_qrcode.png'

//===============================================================================================

function App() {

  //===============================================================================================

  interface OrderData {
    id: string;
    details?: Array<{
      issue: string;
      description: string;
    }>;
    debug_id?: string;
  };

  interface CartItem {
    id:         string;
    gradYear:   string;
    quantity:   string;
    email:      string;
    familyName: string;
    firstName:  string;
    mobile:     string;
    numMinor:   string;
  };

  interface CartData {
    cart: CartItem[];
  };

  interface HCaptchaData {
    token: string;
  }  

  interface Guest {
    id : number;
    name: string;
  }
  
  interface GuestInfo {
    reference_id: string;
    guests?:      string[];
  }

  //===============================================================================================
 
  const menuItems : React.ReactElement[] = [];
  const minorMenuItems : React.ReactElement[] = [];
  const initialOptions: ReactPayPalScriptOptions = {
    clientId: "Ac2aL_DXfLkiN8fsZiY6Cv3nEfGZlPYefSVIcxhHPy9oIgf5wo3lITySBOQirwdsDhwQdz3HbLD7TDZz",
    currency: "HKD",
    intent: "capture",
  };
  const fundingSource : PayPalButtonsComponentProps["fundingSource"] = FUNDING.PAYPAL;
  const hCaptChaSiteKey = "1858dcca-9156-4300-8366-decc6b7cde14";
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  //===============================================================================================

  const[email, setEmail] = useState<string>("");
  const[firstName, setFirstName] = useState<string>("");
  const[familyName, setFamilyName] = useState<string>("");
  const[quantity, setQuantity] = useState<number>(1);
  const[gradYear, setGradYear] = useState<string>("");
  const[mobile, setMobile] = useState<string>("");
  const[numMinor, setNumMinor] = useState<number>(0); 

  const[termsAndCond, setTermsAndCond] = useState<boolean>(false);
  const[under18, setUnder18] = useState<boolean>(false);
  
  const[disableCheckBox, setDisableCheckBox] = useState<boolean>(true);
  const[success, setSuccess] = useState<boolean>(false);
  const[paymentFailure, setPaymentFailure] = useState<boolean>(false);
  const[paymentMethod, setPaymentMethod] = useState<string>("FPS");

  const[openFinishDialog, setOpenFinishDialog] = useState<boolean>(false);
  const[paymentFailureDialog, setPaymentFailureDialog] = useState<boolean>(false);
  const[openGuestReg, setOpenGuestReg] = useState<boolean>(false);
  const[guestNames,  setGuestNames] = useState<Guest[]>([]);
  const[refId, setRefId] = useState<string>("");
  const[customerId, setCustomerId] = useState<string>("");
  const[invoiceId, setInvoiceId] = useState<string>("");
  const[showFPSPaymentDialog, setShowFPSPaymentDialog]=useState<boolean>(false);
  const[amount, setAmount] = useState<string>("");

  const[guestName1,  setGuestName1] = useState<string>("");
  const[guestName2,  setGuestName2] = useState<string>("");
  const[guestName3,  setGuestName3] = useState<string>("");
  const[guestName4,  setGuestName4] = useState<string>("");
  const[guestName5,  setGuestName5] = useState<string>("");
  const[guestName6,  setGuestName6] = useState<string>("");
  const[guestName7,  setGuestName7] = useState<string>("");
  const[guestName8,  setGuestName8] = useState<string>("");
  const[guestName9,  setGuestName9] = useState<string>("");
  const[guestName10, setGuestName10] = useState<string>("");
  const[guestName11, setGuestName11] = useState<string>("");
  const[guestName12, setGuestName12] = useState<string>("");

  //===============================================================================================

  const emailRef = useRef("");
  const firstNameRef = useRef("");
  const familyNameRef = useRef("");
  const quantityRef = useRef(1);
  const gradYearRef = useRef("");
  const mobileRef = useRef("");
  const numMinorRef = useRef(0);

  //===============================================================================================

  for (let i = 1969; i <= 2024; i++) {
    menuItems.push(<MenuItem key={i} value={i.toString()}>{i}</MenuItem>);
  }

  for (let i = 1; i <= 12; i++) {
    minorMenuItems.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
  }

  const onChangeEmail = (input : string) => {
    setEmail(input);
    emailRef.current = input;
  };

  const onChangeFirstName = (name : string) => {
    setFirstName(name);
    firstNameRef.current = name;
  };

  const onChangeLastName = (lastName : string) => {
    setFamilyName(lastName);
    familyNameRef.current = lastName;
  };

  const onChangeMobile = (input : string) => {
    setMobile(input);
    mobileRef.current = input;
  };

  const onChangeQuantity = (input : number) => {
    setQuantity(input);
    quantityRef.current = input;
  };

  const onChangeUnderAgeQuantity = (num : number) => {
    setNumMinor(num);
    numMinorRef.current = num;
  }

  const onChangeGradYear = (year : string) => {
    setGradYear(year);
    gradYearRef.current = year;
  };

  const handleCheckBox = (event : React.ChangeEvent<HTMLInputElement>) => {
    setTermsAndCond(event.target.checked);
  };

  const handleUnderAgeCheckBox = (event : React.ChangeEvent<HTMLInputElement>) => {
    setUnder18(event.target.checked);
    if (event.target.checked === false) {
      numMinorRef.current = 0;
    }
  };

  function getMinorMenuItem() {
    return minorMenuItems.slice(0, (quantityRef.current - 1));
  };

  const onCreateOrderForFPS= async() => {

    try {

      const item: CartItem = {
        id: "2",
        familyName: familyNameRef.current,
        firstName: firstNameRef.current,
        email: emailRef.current,
        mobile: mobileRef.current,
        quantity: quantityRef.current.toString(),
        numMinor: numMinorRef.current.toString(),
        gradYear: gradYearRef.current
      };
      
      const response = await fetch(`/api/ordersByFPS`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(item)
      });

      const reply = await response.json();

      console.log(reply);

      if (reply.result === true) {
        setRefId(reply.reference_id);
        setAmount(reply.amount);
        setShowFPSPaymentDialog(true);
      } else {
        setRefId("");
        setPaymentFailureDialog(true);
      }

    } catch (error) {
      console.log(error);
      setRefId("");
      setPaymentFailureDialog(true);
    }
  };

  const onRegisterGuests = async() => {
    setOpenGuestReg(false);

    let guests : string[] = [];

    guests.push(guestName1, guestName2, guestName3, guestName4, guestName5, guestName6,
                guestName7, guestName8, guestName9, guestName10, guestName11, guestName12);

    guests = guests.filter((item) => item != "");
  
    const guestInfo : GuestInfo = {
      reference_id: refId,
      guests: guests
    };
    
    try {
      const response = await fetch(`/api/guestRegistration`, {
        method: "POST",
        headers: {"Content-Type" : "application/json" },
        body: JSON.stringify(guestInfo)
      });

      window.location.href = "https://www.cskoba.org/";

      const result = await response.json();
      console.log("[onRegisterGuests] : " + result);
      
    }
    catch (error) {
      console.log("[onRegisterGuests] Error:" + error);
      throw error;
    }
  }

  useEffect(()=> {
    if (success == true) {
      setOpenFinishDialog(true);
    } else if (paymentFailure == true) {
      setPaymentFailureDialog(true);
    }
  }, [success, paymentFailure]);
  
  const onCreateOrder : PayPalButtonsComponentProps["createOrder"] = async() => {

    console.log("FamilyName: " + familyNameRef.current);
    console.log("FirstName: " + firstNameRef.current);
    console.log("Email: " + emailRef.current);
    console.log("Mobile: " + mobileRef.current);
    console.log("Grad Year: " + gradYearRef.current);
    console.log("Quantity: " + quantityRef.current);
    console.log("Num Minor: " + numMinorRef.current);
    
    const cartData: CartData = { cart: [] };

    const item: CartItem = {
      id: "1",
      familyName: familyNameRef.current,
      firstName: firstNameRef.current,
      email: emailRef.current,
      mobile: mobileRef.current,
      quantity: quantityRef.current.toString(),
      numMinor: numMinorRef.current.toString(),
      gradYear: gradYearRef.current
    };
    cartData.cart.push(item);

    try {
      const response = await fetch(`/api/orders`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(cartData)
      });

      const orderData: OrderData = await response.json();
      console.log("[onCreateOrder]");
      console.log(orderData);

      if (!orderData.id) {
        const errorDetail = orderData?.details?[0] : Array<{issue: string; description: string;}>;
        throw new Error();
      }

      return orderData.id;
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  };

  const onApproveOrder : PayPalButtonsComponentProps["onApprove"] = async(data) => {
    try {
      const response = await fetch(`/api/orders/${data.orderID}/capture`, {
        method: "POST",
        headers: { "Content-Type": "application/json", },
      });
      const orderData = await response.json();
      console.log("[onApproveOrder]");

      const result = orderData;
      console.log(result);
 
      if ((result.status !== undefined) && (result.status === "COMPLETED")) {
        console.log("[onApproveOrder] purchase_unit[0]: " + result.purchase_units[0]);
        console.log("[onApproveOrder] reference_id: " + result.purchase_units[0].reference_id);
        console.log("[onApproveOrder] invoice_id " + result.purchase_units[0].payments.captures[0].invoice_id);
        console.log("[onApproveOrder] invoice_id " + result.purchase_units[0].payments.captures[0].custom_id);
  
        setRefId(orderData.purchase_units[0].reference_id);
        setCustomerId(result.purchase_units[0].payments.captures[0].invoice_id);
        setInvoiceId(result.purchase_units[0].payments.captures[0].customer_id);
        setSuccess(true);

      } else {
        setPaymentFailure(true);
      }

    } catch (error) {
      console.log("[onApproveOrder] Error: " + error);
      setPaymentFailure(true);
    }
  };

  const onCancel : PayPalButtonsComponentProps["onCancel"] = (data) => {
    console.log("[onCancel]");
    setTermsAndCond(false);
  };

  const onError : PayPalButtonsComponentProps["onError"] = (error) => {
    console.log("==========================");
    console.log("[onError]");
    console.log(error);
    console.log("==========================");
    setTermsAndCond(false);
  };

  const handleHCaptchaSuccessVerification = async (token : string) => {
    console.log("[onVerify] token received");

    const item: HCaptchaData= {
      token: token,
    }

    try {
      const response = await fetch(`/api/verify`, {
        method: "POST",
        headers: { "Content-Type": "application/json", },
        body: JSON.stringify(item)
      });

      const reply = await response.json();
 
      console.log("[onVerify] response:");
      console.log(reply);
      console.log(reply.result);

      if (reply.result === true) {
        setDisableCheckBox(false);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleHCaptchaError = (event : string) => {
    console.log("[onError]");
    console.log(event);
  };

  return (
    <div className="App">
      <Container maxWidth="sm">

        <Box display="flex" justifyContent="center" alignItems="center">
          <img src={csklsclogo} alt="Centered" /><br />
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h6" gutterBottom={true}>Chan Sui Ki (La Salle) College Old Boys' Association Limited</Typography>
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h6" gutterBottom={true}>55th Anniversary Dinner Online Registration</Typography>          
        </Box>

        <Divider sx={{ marginTop: '10px', marginBottom: '10px', marginLeft: '5px', marginRight: '5px' }}/>
        <p> If you encounter any issues during the registration process, please email us at info@cskoba.org.</p>

        <Divider sx={{ marginTop: '10px', marginBottom: '10px', marginLeft: '5px', marginRight: '5px' }}/>
        
        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }} noValidate autoComplete="off" alignItems="center">
          <div>
            <TextField required id="outlined-required" label="Family Name (required)" helperText="e.g. CHAN" onChange={(e) => {onChangeLastName(e.target.value)}} />
            <TextField required id="outlined-required" label="First Name (required)" helperText="e.g. Tai Man" onChange={(e) => {onChangeFirstName(e.target.value)}} />
          </div>
        </Box>

        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }} noValidate autoComplete="off" alignItems="center">
          <div>
            <TextField required id="outlined-required" label="Email Address (required)" helperText="e.g. oldboy1@google.com" onChange={(e) => {onChangeEmail(e.target.value)}} />
            <TextField required id="outlined-required" label="Mobile (required)" helperText="e.g. 98765432" onChange={(e) => {onChangeMobile(e.target.value)}} />
          </div>
        </Box>

        <Divider sx={{ marginTop: '10px', marginBottom: '10px', marginLeft: '5px', marginRight: '5px' }} />

        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }} noValidate autoComplete="off" alignItems="center">
          <div>
          <FormControl sx={{ mt: 2, mb: 2, ml: 1, mr: 1, display: 'flex'}}>
          <InputLabel>Year of F5/DSE/Last Year of School </InputLabel> 
            <Select value={gradYear} label="Year of F5/DSE/Last Year of School" onChange={(e) => onChangeGradYear(e.target.value)}>
              {menuItems}              
            </Select>
          </FormControl>
          </div>
        </Box>

        <Divider sx={{ marginTop: '10px', marginBottom: '10px', marginLeft: '5px', marginRight: '5px' }} />

        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }} noValidate autoComplete="off" alignItems="center">
          <div>
          <FormControl sx={{ mt: 2, mb: 2, ml: 1, mr: 1, display: 'flex', width: 150}}>
            <InputLabel>Num of Guests</InputLabel> 
            <Select value={quantity} label="Num of Tickets" onChange={(e) => onChangeQuantity(Number(e.target.value))}>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={11}>11</MenuItem>
              <MenuItem value={12}>12</MenuItem>
            </Select>
          </FormControl>
          </div>
          { (Number(quantityRef.current) > 1) ?
          <div>
            <Checkbox {...label} required  size="medium" onChange={handleUnderAgeCheckBox}/>Any persons under the age of 18?<br/>
            { under18 ?
              <FormControl sx={{ mt: 2, mb: 2, ml: 1, mr: 1, display: 'flex', width: 250}}>
              <InputLabel>Num of Guests under the Age of 18 </InputLabel>
              <Select value={numMinor} label="Num of Persons under the Age of 18" onChange={(e) => onChangeUnderAgeQuantity(Number(e.target.value))}>
                {getMinorMenuItem()}
              </Select>
            </FormControl> : null}
          </div> : null }
        </Box>

        <Divider sx={{ marginTop: '10px', marginBottom: '10px', marginLeft: '5px', marginRight: '5px' }} />

        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }} noValidate autoComplete="off" alignItems="center">
          <FormControl sx={{ mt: 2, mb: 2, ml: 1, mr: 1, display: 'flex', width: 250}}>
            <InputLabel>Payment Method</InputLabel>
            <Select value={paymentMethod} label="Payment Method" onChange={(e) => setPaymentMethod(e.target.value)}>
                <MenuItem key={1} value="FPS">FPS</MenuItem>
                <MenuItem key={2} value="PayPal">PayPal</MenuItem>
            </Select>
          </FormControl>
        </Box>        

        <Divider sx={{ marginTop: '10px', marginBottom: '10px', marginLeft: '5px', marginRight: '5px' }} />

        <HCaptcha sitekey={hCaptChaSiteKey}
          onVerify={(token) => handleHCaptchaSuccessVerification(token)}
          onError={(e) => handleHCaptchaError(e)}
          />

        <Divider sx={{ marginTop: '10px', marginBottom: '10px', marginLeft: '5px', marginRight: '5px' }} />

        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }} noValidate autoComplete="off" alignItems="center">
          <TermsAndConditionsModal disable={disableCheckBox} />
          <Checkbox {...label} required checked={termsAndCond} size="medium" onChange={handleCheckBox} disabled={disableCheckBox}/> I understand and agree the Terms and Conditions.
        </Box>


        
        <Divider sx={{ marginTop: '10px', marginBottom: '10px', marginLeft: '5px', marginRight: '5px' }} />

        { paymentMethod === "PayPal" && 
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              disabled={!termsAndCond}
              createOrder={onCreateOrder}
              onApprove={onApproveOrder}
              onError={onError}
              onCancel={onCancel}
              fundingSource={fundingSource}
              />
          </PayPalScriptProvider>
        }

        { paymentMethod === "FPS" && 
         <Button disabled={!termsAndCond} variant="contained" onClick={onCreateOrderForFPS}>Create Order for FPS</Button>
        }

        <Dialog open={openFinishDialog}>
          <DialogTitle>Payment Success</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Thank you for your participation. See you in the dinner !
              <p>reference id: {refId}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => {setOpenFinishDialog(false); setOpenGuestReg(true);}}>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={showFPSPaymentDialog}>
          <DialogTitle>FPS Payment Details</DialogTitle>
          <DialogContent>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img src={cskoba_fps_qrcode} alt="Centered" /><br />
            </Box>
            <DialogContentText>
              <ul>
                <li>Reference Id: {refId}</li>
                <li>Please scan the QR code and enter the correct amount: <b>${amount}</b>.</li>
                <li>Please enter your name, mobile number, and reference Id into the message section.</li>
                <li>Please keep a screen capture after you successfully finish the payment process.</li>
                <li>Your booking will be cancelled if we do not receive the payment within 24 hours without notifications.</li>
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => {setShowFPSPaymentDialog(false); setOpenGuestReg(true);}}>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={paymentFailureDialog}>
          <DialogTitle>Payment Failure</DialogTitle>
          <DialogContent>
            <DialogContentText>
              { refId !== "" && <p>Please contact info@cskoba.org for further information.</p> }
              { refId === "" && <p>Transaction Declined. Please register again.</p> }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => {setPaymentFailureDialog(false);}}>Close</Button>
          </DialogActions>
        </Dialog>


        <Dialog open={openGuestReg} maxWidth="md" fullWidth={true}>
          <DialogTitle>Guest Registration {quantityRef.current}</DialogTitle>
          <IconButton
          aria-label="close"
          onClick={(e) => {setOpenGuestReg(false)}}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}>
          <CloseIcon />
          </IconButton>
          <Box sx={{display: 'flex', m: 'auto', width: 'fit-content'}}>
          <DialogContentText>
            <p>Please register the name of the guests:</p>
            <TextField id="outlined-required" label="Guest's Name #1" helperText="e.g. CHAN TAI MAN" onChange={(e) => {setGuestName1(e.target.value)}}/>
            { (quantityRef.current > 1)  && <TextField id="outlined-required" label="Guest's Name #2"  helperText="e.g. CHAN TAI MAN" onChange={(e) => {setGuestName2(e.target.value)}}/> }
            { (quantityRef.current > 2)  && <TextField id="outlined-required" label="Guest's Name #3"  helperText="e.g. CHAN TAI MAN" onChange={(e) => {setGuestName3(e.target.value)}}/> }
            { (quantityRef.current > 3)  && <TextField id="outlined-required" label="Guest's Name #4"  helperText="e.g. CHAN TAI MAN" onChange={(e) => {setGuestName4(e.target.value)}}/> }
            { (quantityRef.current > 4)  && <TextField id="outlined-required" label="Guest's Name #5"  helperText="e.g. CHAN TAI MAN" onChange={(e) => {setGuestName5(e.target.value)}}/> }
            { (quantityRef.current > 5)  && <TextField id="outlined-required" label="Guest's Name #6"  helperText="e.g. CHAN TAI MAN" onChange={(e) => {setGuestName6(e.target.value)}}/> }
            { (quantityRef.current > 6)  && <TextField id="outlined-required" label="Guest's Name #7"  helperText="e.g. CHAN TAI MAN" onChange={(e) => {setGuestName7(e.target.value)}}/> }
            { (quantityRef.current > 7)  && <TextField id="outlined-required" label="Guest's Name #8"  helperText="e.g. CHAN TAI MAN" onChange={(e) => {setGuestName8(e.target.value)}}/> }
            { (quantityRef.current > 8)  && <TextField id="outlined-required" label="Guest's Name #9"  helperText="e.g. CHAN TAI MAN" onChange={(e) => {setGuestName9(e.target.value)}}/> }
            { (quantityRef.current > 9)  && <TextField id="outlined-required" label="Guest's Name #10" helperText="e.g. CHAN TAI MAN" onChange={(e) => {setGuestName10(e.target.value)}}/> }
            { (quantityRef.current > 10) && <TextField id="outlined-required" label="Guest's Name #11" helperText="e.g. CHAN TAI MAN" onChange={(e) => {setGuestName11(e.target.value)}}/> }
            { (quantityRef.current > 11) && <TextField id="outlined-required" label="Guest's Name #12" helperText="e.g. CHAN TAI MAN" onChange={(e) => {setGuestName12(e.target.value)}}/> }
          </DialogContentText>
          </Box>
          <DialogActions>
            <Button onClick={onRegisterGuests}>Register</Button>
          </DialogActions>
        </Dialog>

        <Divider sx={{ marginTop: '10px', marginBottom: '10px', marginLeft: '5px', marginRight: '5px' }} />

      </Container>
    </div>
    
  );
}

export default App;
