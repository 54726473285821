import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export interface Props {
  disable : boolean;
}

function TermsAndConditionsModal(props : Props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button variant="contained" onClick={handleOpen} disabled={props.disable}>Click Here to Review Terms and Conditions</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Terms and Conditions
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <ul>
                <li>The personal data collected will be used in organizing the CSK 55th Anniversary Dinner and for future communication by 
                    Chan Sui Ki (La Salle) College Old Boys' Association Limited ("CSKOBA") and 
                    Chan Sui Ki (La Salle) College.
                </li>
				        <li>CSKOBA is at sole discretion for seating arrangement. Seating will be normally arranged according to the year of graduation.</li>
				        <li>Each person could order up to 12 seats only.</li>
				        <li>Subject to the seat availability and other conditions, CSKOBA may not be able to fulfill your complete booking. You may purchase up to the remaining seats available determined by the system.</li>
                <li>Please email at info@cskoba.org if you have any questions or concerns.</li>
            </ul>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default TermsAndConditionsModal;